navigator &&
    navigator.serviceWorker &&
    navigator.serviceWorker.getRegistrations &&
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
        if (!registrations.length) {
            return;
        }
        registrations.forEach((registration) => {
            registration.unregister().then(function (boolean) {
                console.log(
                    boolean ? 'Successfully unregistered' : 'Failed to unregister',
                    'ServiceWorkerRegistration\n' +
                        (registration.installing ? '  .installing.scriptURL = ' + registration.installing.scriptURL + '\n' : '') +
                        (registration.waiting ? '  .waiting.scriptURL = ' + registration.waiting.scriptURL + '\n' : '') +
                        (registration.active ? '  .active.scriptURL = ' + registration.active.scriptURL + '\n' : '') +
                        '  .scope: ' +
                        registration.scope +
                        '\n'
                );
            });
        });
    });
